<template v-slot:body>

    <app-modal v-show="isEditModalOpen" :heading="modalHeading">

        <template #close>
            <button
                @click="closeModal"
                class="close-modal"
            >
                <span class="material-symbols-outlined">close</span>
            </button>
        </template>

        <form @submit.prevent="saveCategory">

            <div v-if="showMessage == true" v-html="message"></div>
            
            <div class="mb-3 sm:col-span-4 block text-sm leading-6 text-left text-white"><span class="font-black">ID:</span> {{ category.category_id }}</div>

            <app-form-row label="Category Name" id="categoryName">
                <input
                    required
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="categoryName"
                    type="text"
                    v-model="category.category_name"
                    @keydown.enter.prevent="saveCategory">
            </app-form-row>

            {{ modalBody }}

        </form>

        <template #buttons>
            <button
                type="submit"
                class="primary w-full sm:w-auto"
                :disabled="isSaving"
                @click="saveCategory"
            >
                Save Category
            </button>
        </template>
            
    </app-modal>
    
</template>

<script>
import { ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import apiClient from '@/api/apiClient'; 
import AppModal from "@/components/common/AppModal";
import AppFormRow from '@/components/common/AppFormRow.vue';

export default {
    components: {
        AppModal,
        AppFormRow,
    },
    props: {
        isEditModalOpen: {
            type: Boolean,
            required: true,
        },
        selectedCategory: {
            type: Object,
            required: false,
            default: () => ({ category_id: null, category_name: null }), // Default empty object
        },
    },
    setup(props, { emit }) {
        const toast = useToast();
        const category = ref({});
        const showMessage = ref(false);
        const message = ref('');
        const modalHeading = ref('Edit Category');
        const modalBody = ref('');
        const isSaving = ref(false);

        const closeModal = () => {
            emit('update:isEditModalOpen', false);
        };

        const saveCategory = () => {
            if (isSaving.value) return;

            isSaving.value = true;

            const categoryData = {
                categoryId: category.value.category_id,
                categoryName: category.value.category_name,
            };

            apiClient.post('/edit-category.php', categoryData)
            .then(response => {
                if (response.data.success === true) {
                    emit('category-saved'); // tell view it was saved so it will refresh the tables

                    // clear all
                    category.value = {};
                    showMessage.value = false;
                    closeModal();

                    // show toaster
                    toast.success('Category edited successfully!');
                } else {
                    console.error('Failed to edited category.');
                    showMessage.value = true;
                    message.value = response.data.message;
                    toast.error('Failed to edited category!');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error editing category!');
            })
            .finally(() => {
                isSaving.value = false;
            });
        };

        const fetchCategoryDetails = (selectedCategory) => {
            // console.log('fetchCategoryDetails ID:', selectedCategory);
            apiClient.get('/get-category-details.php?categoryId=' + selectedCategory)
            .then((response) => {
                // Populate the category object with the fetched data
                category.value = response.data;
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
        };

        watch(() => props.isEditModalOpen, (newVal) => {
            if (newVal) {
                fetchCategoryDetails(props.selectedCategory.category_id);
            }
        });

        return {
            category,
            showMessage,
            message,
            modalHeading,
            modalBody,
            closeModal,
            saveCategory,
            fetchCategoryDetails,
            isSaving
        };
    }
};
</script>

<style>
/* Add custom styles for the modal as per your design */
</style>