<template v-slot:body>

    <app-modal v-show="isAddModalOpen" :heading="modalHeading">

        <template #close>
            <button
                @click="closeModal"
                class="close-modal"
            >
                <span class="material-symbols-outlined">close</span>
            </button>
        </template>

        <form @submit.prevent="saveUser">

            <div v-if="showMessage == true" v-html="message"></div>

            <app-form-row label="Username" id="addUsername">
                <input
                    required
                    type="text"
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="addUsername"
                    v-model="user.username"
                    @keydown.enter.prevent="saveUser">
            </app-form-row>

            <app-form-row label="Email" id="addEmail">
                <input
                    required
                    type="email"
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="addEmail"
                    v-model="user.email"
                    @keydown.enter.prevent="saveUser">
            </app-form-row>

            <app-form-row label="Password" id="addPassword">
                <input
                    required
                    type="password"
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white
                        placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white
                          sm:text-sm sm:leading-6"
                    name="addPassword"
                    v-model="user.password"
                    @keydown.enter.prevent="saveUser">
            </app-form-row>

            {{ modalBody }}

        </form>

        <template #buttons>
            <button
                type="submit"
                class="primary w-full sm:w-auto"
                :disabled="isSaving"
                @click="saveUser"
            >
                Add User
            </button>
        </template>
            
    </app-modal>
    
</template>

<script>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import apiClient from '@/api/apiClient'; 
import AppModal from "@/components/common/AppModal";
import AppFormRow from '@/components/common/AppFormRow.vue';

export default {
    components: {
        AppModal,
        AppFormRow,
    },
    props: {
        isAddModalOpen: {
            type: Boolean,
            required: true
        },
    },
    setup(props, { emit }) {
        const toast = useToast();
        const user = ref({});
        const showMessage = ref(false);
        const message = ref('');
        const modalHeading = ref('Add New User');
        const modalBody = ref('');
        const isSaving = ref(false);

        const closeModal = () => {
            emit('update:isAddModalOpen', false);
        };

        const saveUser = () => {
            if (isSaving.value) return;

            isSaving.value = true;

            const userData = {
                username: user.value.username,
                email: user.value.email,
                password: user.value.password,
                internal: true
            };

            apiClient.post('/add-user.php', userData)
            .then(response => {
                if (response.data.success === true) {
                    emit('user-saved'); // tell view it was saved so it will refresh the tables

                    // clear all
                    user.value = {};
                    showMessage.value = false;
                    closeModal();

                    // show toaster
                    toast.success('User added successfully!');
                } else {
                    console.error('Failed to create user.');
                    showMessage.value = true;
                    message.value = response.data.message;
                    toast.error('Failed to create user!');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error adding user!');
            })
            .finally(() => {
                isSaving.value = false;
            });
        };

        return {
            user,
            showMessage,
            message,
            modalHeading,
            modalBody,
            closeModal,
            saveUser,
            isSaving
        };
    }
};
</script>

<style>
/* Add custom styles for the modal as per your design */
</style>