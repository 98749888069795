<template v-slot:body>

    <app-modal v-show="isEditModalOpen" :heading="modalHeading">

        <template #close>
            <button
                @click="closeModal"
                class="close-modal"
            >
                <span class="material-symbols-outlined">close</span>
            </button>
        </template>

        <form @submit.prevent="saveExpense">
            
            <app-form-row label="Subcategory" id="editSubcategory">
                <select
                    required
                    name="editSubcategory"
                    v-model="expense.subcat"
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                >
                    <option value="" selected disabled>Select Subcategory</option>
                    <optgroup v-for="(subcategories, category) in groupedSubcategories" :label="category" :key="category">
                        <option v-for="subcategory in subcategories" :value="subcategory.id" :key="subcategory.id">
                        {{ subcategory.sub }}
                        </option>
                    </optgroup>
                </select>
            </app-form-row>

            <app-form-row label="Value" id="editValue">
                <input
                    required
                    type="number"
                    class="mt-1 block w-full rounded-md h-10 py-2 pl-4 pr-2 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="editValue"
                    v-model="expense.value"
                    @keydown.enter.prevent="saveExpense">
            </app-form-row>

            <app-form-row label="Note" id="editNote">
                <input
                    type="text"
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="editNote"
                    v-model="expense.note"
                    @keydown.enter.prevent="saveExpense">
            </app-form-row>

            <app-form-row label="Date" id="editDate">
                <input
                    required
                    type="date"
                    class="date-mobile-full mt-1 block rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="editDate"
                    v-model="expense.date"
                    @keydown.enter.prevent="saveExpense">
            </app-form-row>

            {{ modalBody }}

        </form>

        <template #buttons>
            <button
                type="submit"
                class="primary w-full sm:w-auto"
                :disabled="isSaving"
                @click="saveExpense"
            >
                Save Expense
            </button>
        </template>
            
    </app-modal>
    
</template>

<script>
import { ref, watch, computed } from 'vue';
import { useToast } from 'vue-toastification';
import apiClient from '@/api/apiClient'; 
import AppModal from "@/components/common/AppModal";
import AppFormRow from '@/components/common/AppFormRow.vue';

export default {
    components: {
        AppModal,
        AppFormRow,
    },
    props: {
        isEditModalOpen: {
            type: Boolean,
            required: true,
        },
        selectedExpense: {
            type: Object,
            required: false, // Not required anymore, as it has a default value
            default: () => ({ subcategory_id: null, date: null, note: null, value: null }), // Default empty object
        },
    },
    setup(props, { emit }) {
        const toast = useToast();
        const subcategoriesList = ref([]);
        const expense = ref({});
        const modalHeading = ref('Add New Expense');
        const modalBody = ref('');
        const isSaving = ref(false);
        
        const closeModal = () => {
            emit('update:isEditModalOpen', false);
        };

        const fetchSubcategories = () => {
            apiClient.get('/get-subcategories.php')
            .then((response) => {
                // console.log(response.data);
                subcategoriesList.value = response.data;
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error);
            });
        };
        
        const fetchExpenseDetails = () => {
            apiClient.get('/get-expense-details.php?expenseId=' + props.selectedExpense.id)
            .then((response) => {
                expense.value.id = parseInt(response.data.id);
                expense.value.subcat = parseInt(response.data.subcategory_id);
                expense.value.date = response.data.date;
                expense.value.oldDate = response.data.date;
                expense.value.note = response.data.note;
                expense.value.value = response.data.value;
            })
            .catch((error) => {
                console.error('Error fetching expense details:', error);
            });
        };

        const groupedSubcategories = computed(() => {
            const grouped = {};
            subcategoriesList.value.forEach((subcategory) => {
                if (!grouped[subcategory.cat]) {
                    grouped[subcategory.cat] = [];
                }
                grouped[subcategory.cat].push(subcategory);
            });
            return grouped;
        });

        // Watcher to fetch subcategories when modal opens
        watch(() => props.isEditModalOpen, (newVal) => {
            if (newVal) {
                fetchSubcategories();
                fetchExpenseDetails();
            } else {
                resetForm();
            }
        });

        // Methods ------------------------------------------------
        const resetForm = () => {
            expense.value = {};
        };

        const saveExpense = () => {
            if (isSaving.value) return;

            isSaving.value = true;

            const expenseData = {
                expenseId: expense.value.id,
                subcat: expense.value.subcat,
                date: expense.value.date,
                oldDate: expense.value.oldDate,
                note: expense.value.note,
                value: expense.value.value
            };
            
            apiClient.post('/edit-expense.php', expenseData)
            .then(response => {
                if (response.data.updateMessage === 'success') {
                    emit('expense-edited', response.data.editedExpenseDate); // tell view it was saved so it will refresh the tables

                    // clear all
                    expense.value = {};
                    closeModal();

                    // show toaster
                    toast.success('Expense edited successfully!');
                } else {
                    console.error('Failed to edit expense.');
                    toast.error('Failed to edit expense!');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error editing expense!');
            })
            .finally(() => {
                isSaving.value = false;
            });
        };

        return {
            subcategoriesList,
            expense,
            modalHeading,
            modalBody,
            closeModal,
            fetchSubcategories,
            groupedSubcategories,
            saveExpense,
            isSaving
        };
    }
};
</script>

<style>
.date-mobile-full {
    min-width: -webkit-fill-available;
}
</style>