<template v-slot:body>

    <app-modal v-show="isEditModalOpen" :heading="modalHeading">

        <template #close>
            <button
                @click="closeModal"
                class="close-modal"
            >
                <span class="material-symbols-outlined">close</span>
            </button>
        </template>

        <form @submit.prevent="saveSubcategory">

            <div v-if="showMessage == true" v-html="message"></div>
            
            <div class="mb-3 sm:col-span-4 block text-sm leading-6 text-left text-white"><span class="font-black">ID:</span> {{ subcategory.subcategory_id }}</div>

            <!-- v-model comes from get-subcategory-details.php mysql query -->

            <app-form-row label="Subcategory Name" id="subcategoryName">
                <input
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="subcategoryName"
                    id="subcategoryName"
                    type="text"
                    v-model="subcategory.subcategory_name"
                    @keydown.enter.prevent="saveSubcategory"
                    required>
            </app-form-row>
            
            <app-form-row label="Category" id="categoryId">
                <select
                    id="categoryId"
                    name="categoryId"
                    v-model="subcategory.category_id"
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    required
                >
                    <option value="" selected disabled>Select Category</option>
                    <option v-for="category in categoriesList" :value="category.category_id" :key="category.category_id" :selected="category.category_id === subcategory.category_id">
                        {{ category.category_name }}
                    </option>
                </select>
            </app-form-row>

            {{ modalBody }}

        </form>

        <template #buttons>
            <button
                type="submit"
                class="primary w-full sm:w-auto"
                :disabled="isSaving"
                @click="saveSubcategory"
            >
                Save Subcategory
            </button>
        </template>
            
    </app-modal>
    
</template>

<script>
import { ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import apiClient from '@/api/apiClient'; 
import AppModal from "@/components/common/AppModal";
import AppFormRow from '@/components/common/AppFormRow.vue';

export default {
    components: {
        AppModal,
        AppFormRow,
    },
    props: {
        isEditModalOpen: {
            type: Boolean,
            required: true,
        },
        selectedSubcategory: {
            type: Object,
            required: false,
            default: () => ({ subcategory_id: null, subcategory_name: null, category_id: null }), // Default empty object
        },
    },
    setup(props, { emit }) {
        const toast = useToast();
        const categoriesList = ref([]);
        const subcategory = ref({});
        const showMessage = ref(false);
        const message = ref('');
        const modalHeading = ref('Edit Subcategory');
        const modalBody = ref('');
        const isSaving = ref(false);

        const closeModal = () => {
            emit('update:isEditModalOpen', false);
        };

        const fetchCategories = () => {
            apiClient.post('/get-categories.php')
            .then((response) => {
                // console.log(response.data);
                categoriesList.value = response.data;
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
        };

        const fetchSubcategoryDetails = (selectedSubcategory) => {
            apiClient.get('/get-subcategory-details.php?subcategoryId=' + selectedSubcategory)
            .then((response) => {
                // console.log(response.data);
                subcategory.value = response.data;
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error);
            });
        };

        const saveSubcategory = () => {
            if (isSaving.value) return;

            isSaving.value = true;

            const subcategoryData = {
                subcategoryId: subcategory.value.subcategory_id,
                subcategoryName: subcategory.value.subcategory_name,
                categoryId: subcategory.value.category_id
            };
            apiClient.post('/edit-subcategory.php', subcategoryData)
            .then(response => {
                if (response.data.success === true) {
                    emit('subcategory-saved'); // tell view it was saved so it will refresh the tables

                    // clear all
                    subcategory.value = {};
                    showMessage.value = false;
                    closeModal();

                    // show toaster
                    toast.success('Subcategory edited successfully!');
                } else {
                    console.error('Failed to edit subcategory.');
                    showMessage.value = true;
                    message.value = response.data.message;
                    toast.error('Failed to edit subcategory!');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error editing subcategory!');
            })
            .finally(() => {
                isSaving.value = false;
            });
        };

        // Watcher to fetch categories when modal opens
        watch(() => props.isEditModalOpen, (isOpen) => {
            if (isOpen) {
                fetchCategories();
            }
        });

        // Watcher to fetch the current subcategory when modal opens
        watch(() => props.isEditModalOpen, (newVal) => {
            if (newVal) {
                fetchSubcategoryDetails(props.selectedSubcategory.subcategory_id);
            }
        });

        return {
            categoriesList,
            subcategory,
            showMessage,
            message,
            modalHeading,
            modalBody,
            closeModal,
            fetchCategories,
            fetchSubcategoryDetails,
            saveSubcategory,
            isSaving
        };
    }
};
</script>

<style>
/* Add custom styles for the modal as per your design */
</style>