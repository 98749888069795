<template v-slot:body>

    <app-modal v-show="isEditModalOpen" :heading="modalHeading">

        <template #close>
            <button
                @click="closeModal"
                class="close-modal"
            >
                <span class="material-symbols-outlined">close</span>
            </button>
        </template>

        <form @submit.prevent="saveUser">

            <div v-if="showMessage == true" v-html="message"></div>
            
            <div class="mb-3 sm:col-span-4 block text-sm leading-6 text-left text-white"><span class="font-black">ID:</span> {{ user.user_id }}</div>
            
            <div class="mb-3 sm:col-span-4 block text-sm leading-6 text-left text-white"><span class="font-black">Name:</span> {{ user.name }}</div>
            
            <div class="mb-3 sm:col-span-4 block text-sm leading-6 text-left text-white"><span class="font-black">Username:</span> {{ user.username }}</div>

            <div class="mb-3 sm:col-span-4 block text-sm leading-6 text-left text-white"><span class="font-black">Email:</span> {{ user.email }}</div>

            <hr class="my-8 border-gray-950" />

            <app-form-row label="" id="userName">
                <input
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="userName"
                    type="text"
                    v-model="newName"
                    @keydown.enter.prevent="saveUser"
                    placeholder="New Name">
            </app-form-row>

            <app-form-row label="" id="userUsername">
                <input
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="userUsername"
                    type="text"
                    v-model="newUsername"
                    @keydown.enter.prevent="saveUser"
                    placeholder="New Username">
            </app-form-row>

            <app-form-row label="" id="userEmail">
                <input
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="userEmail"
                    type="email"
                    v-model="newEmail" 
                    @keydown.enter.prevent="saveUser"
                    placeholder="New Email">
            </app-form-row>

            <app-form-row label="" id="userPassword">
                <input
                    class="mt-1 block w-full rounded-md h-10 py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    name="userPassword"
                    type="password"
                    v-model="newPassword"
                    @keydown.enter.prevent="saveUser"
                    placeholder="New Password">
            </app-form-row>

            {{ modalBody }}

        </form>

        <template #buttons>
            <button
                type="submit"
                class="primary w-full sm:w-auto"
                :disabled="isSaving"
                @click="saveUser"
            >
                Save User
            </button>
        </template>
            
    </app-modal>
    
</template>

<script>
import { ref, inject, watch } from 'vue';
import { useToast } from 'vue-toastification';
import apiClient from '@/api/apiClient'; 
import AppModal from "@/components/common/AppModal";
import AppFormRow from '@/components/common/AppFormRow.vue';

export default {
    components: {
        AppModal,
        AppFormRow,
    },
    props: {
        isEditModalOpen: {
            type: Boolean,
            required: true,
        },
        selectedUser: {
            type: Object,
            required: false,
            default: () => ({ user_id: null, name: null, username: null, email: null, password: null }), // Default empty object
        },
    },
    setup(props, { emit }) {
        const toast = useToast();
        const user = ref({});
        const showMessage = ref(false);
        const message = ref('');
        const modalHeading = ref('Edit User');
        const modalBody = ref('');
        const newName = ref('');
        const newUsername = ref('');
        const newEmail = ref('');
        const newPassword = ref('');
        const eventBus = inject('eventBus');
        const isSaving = ref(false);

        const closeModal = () => {
            showMessage.value = false;
            emit('update:isEditModalOpen', false);
        };

        const fetchUserDetails = (userId) => {
            apiClient.get('/get-user-details.php', {
                params: {
                    userId: userId
                }
            })
            .then((response) => {
                user.value = response.data;
                resetFormFields();
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
            });
        };

        const resetFormFields = () => {
            newName.value = '';
            newUsername.value = '';
            newEmail.value = '';
            newPassword.value = '';
        };

        const saveUser = () => {
            if (isSaving.value) return;

            isSaving.value = true;

            const userData = {
                userId: user.value.user_id,
                userName: newName.value,
                userUsername: newUsername.value,
                userEmail: newEmail.value,
                userPassword: newPassword.value
            };

            apiClient.post('/edit-user.php', userData)
            .then((response) => {
                if (response.data.success === true) {
                    emit('user-saved', userData); // Emita evento para atualizar dados em Setup.vue

                    if (eventBus) {
                        eventBus.$emit('user-details-updated', userData);
                    } else {
                        console.error('Event bus not found.');
                    }

                    showMessage.value = false;
                    closeModal();
                    
                    // show toaster
                    toast.success('User edited successfully!');
                } else {
                    console.error('Failed to edit user!');
                    showMessage.value = true;
                    message.value = response.data.message;
                    toast.error('Failed to edited user!');
                }
            })
            .catch((error) => {
                console.error('Error saving user:', error);
            })
            .finally(() => {
                isSaving.value = false;
            });
        };

        // Watch for changes in isEditModalOpen prop
        watch(() => props.isEditModalOpen, (newVal) => {
            if (newVal) {
                fetchUserDetails(props.selectedUser.user_id);
            } else {
                user.value = {};
                resetFormFields();
            }
        });

        return {
            user,
            showMessage,
            message,
            modalHeading,
            modalBody,
            newName,
            newUsername,
            newEmail,
            newPassword,
            closeModal,
            fetchUserDetails,
            resetFormFields,
            saveUser,
            isSaving
        };
    }
};
</script>

<style>
/* Add custom styles for the modal as per your design */
</style>