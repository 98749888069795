<template>

    <!-- Loader -->
    <app-loader :loading="loading" />

    <div class="py-2 px-4 bg-gray-800 sm:px-8">

        <h1 class="flex pt-3 pb-8 items-center border-b border-gray-700 mb-4 sm:pt-6">
            <div class="text-2xl font-bold text-gray-200 sm:text-3xl">Expenses</div>
            <span
                v-if="totalExpense > 0"
                class="ml-auto text-1xl font-bold text-red-500 px-4 py-2
                       inline-flex justify-center
                       shadow-sm ring-1 ring-inset ring-gray-900
                       rounded-md bg-gray-900/70"
            >
                Total: ${{ totalExpense }}
            </span>
        </h1>

        <button class="btn-filters" @click="toggleFilters" aria-label="Expand Filters">
            <span class="ml-2">Show Filters</span>
            <span class="material-symbols-outlined">keyboard_arrow_down</span>
        </button>

        <div class="filters-wrapper" :class="{ 'show-filters': showFilters }">

            <!-- Month selection dropdown -->
            <form method="GET" class="block w-full sm:w-auto">
                <div class="flex flex-col gap-2 place-items-start">
                    <label for="monthSelect" class="text-gray-200">Filter by Month:</label>
                    <select
                        name="month"
                        id="monthSelect"
                        @change="updateMonth($event.target.value)"
                        class="w-full h-10 py-2 px-4 rounded-md bg-gray-900 border-gray-900 border text-white sm:w-40">
                        <option value="all">All Months</option>
                        <option v-for="month in monthsList" :value="month" :key="month" :selected="month === selectedSetMonth">{{ month }}</option>
                    </select>
                </div>
            </form>

            <!-- Category selection dropdown -->
            <form method="GET" class="block w-full sm:w-auto">
                <div class="flex flex-col gap-2 place-items-start">
                    <label for="categorySelect" class="text-gray-200">Filter by Category:</label>
                    <select
                        id="subcat"
                        name="subcat"
                        @change="updateSubcategory($event.target.value)"
                        class="w-full h-10 py-2 px-4 rounded-md bg-gray-900 border-gray-900 border text-white sm:w-80"
                    >
                        <option value="all">All Categories</option>
                        <optgroup v-for="(subcategories, category) in groupedSubcategories" :label="category" :key="category">
                            <option v-for="subcategory in subcategories" :value="subcategory.id" :key="subcategory.id">
                                {{ subcategory.sub }}
                            </option>
                        </optgroup>
                    </select>
                </div>
            </form>

            <!-- Search -->
            <form method="GET" class="block w-full sm:w-auto">
                <div class="flex flex-col gap-2 place-items-start relative">
                    <label for="searchInput" class="text-gray-200">Search:</label>
                    <input
                        class="w-full h-10 py-2 px-4 rounded-md bg-gray-900 border-gray-900 border text-white sm:w-60"
                        name="search"
                        id="searchInput"
                        type="text"
                        v-model="searchTerm"
                        @input="fetchExpensesData"
                        @keypress.enter.prevent />
                    <!-- Clear button -->
                    <button
                        v-if="searchTerm"
                        @click="clearSearchTerm"
                        class="absolute right-0 bottom-1 w-8 h-8
                                flex items-center justify-center
                                text-red-500 hover:text-white
                                cursor-pointer"
                    >
                        <span class="material-symbols-outlined">close</span>
                    </button>
                    <!-- Loader -->
                    <!-- <div v-if="loading" class="absolute right-0 bottom-1 w-8 h-8 bg-gray-900 flex items-center justify-center">
                        <span class="animate-spin h-6 w-6 text-white material-symbols-outlined">cached</span>
                    </div> -->
                </div>
            </form>

            <!-- Show only recurring expenses -->
            <div class="w-full flex flex-row items-center gap-2 pt-2 pb-2 ml-0 sm:w-auto sm:ml-auto sm:pt-0 sm:pb-2">
                <input
                    class="block w-6 h-6 focus:ring-2 focus:ring-inset focus:ring-white"
                    name="checkRecurring"
                    id="checkRecurring"
                    type="checkbox"
                    v-model="showRecurringOnly"
                    @change="handleCheckboxChange" />
                <label for="checkRecurring" class="text-white">Show only recurring expenses</label>
            </div>
        </div>

        <!-- Table of Expenses -->
        <app-table
            :columns="expenseColumns"
            :data="filteredExpenses"
            :show-actions="true"
            :actions-column-label="actionsColumnLabel"
            :sortable="true"
            :sort-option="selectedSortOption"
            :sort-direction="selectedSortDirection"
            @sort-changed="handleSortChange"
        >
            <template v-slot:actions="{ item }">
                <!-- {{ item.id }} - {{ item.date }} -->
                <div class="flex flex-row gap-4 justify-end">
                    <button
                        @click="editExpense(item)"
                        class="inline-flex justify-center items-center gap-2 w-full rounded-md px-3 py-2 bg-sky-700 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-sky-700
                               sm:w-auto sm:p-0 sm:m-0 sm:ring-0 sm:bg-transparent
                               sm:hover:bg-transparent sm:hover:text-sky-400"
                    >
                        <span class="material-symbols-outlined hidden sm:block sm:text-3xl sm:w-9 sm:h-9 sm:m-0">edit</span>
                        <span class="text-base sm:hidden">Edit</span>
                    </button>
                    <button
                        @click="deleteExpense(item)"
                        class="inline-flex justify-center items-center gap-2 w-full rounded-md px-3 py-2 bg-transparent text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-red-500
                               sm:w-auto sm:p-0 sm:m-0 sm:ring-0
                               sm:hover:bg-transparent sm:hover:text-red-700"
                    >
                        <span class="material-symbols-outlined hidden sm:block sm:text-3xl sm:w-9 sm:h-9 sm:m-0">delete_forever</span>
                        <span class="text-base sm:hidden">Delete</span>
                    </button>
                </div>
            </template>
        </app-table>

        <!-- Show message when there are no expenses -->
        <div v-if="filteredExpenses.length === 0" class="p-4 mb-8 bg-gray-700 text-white rounded-md text-center py-16">
            <p v-html="noDataMessage"></p>
        </div>

        <!-- Pagination -->
        <app-pagination
            v-if="totalPages > 1"
            :current-page="currentPage"
            :total-pages="totalPages"
            :max-visible-pages="5" 
            @update-current-page="handlePageChange"
        />

        <!-- Add some extra space here sofab button doesn't cover edit/delete button when now pagination is added -->
        <div class="h-20"></div>

        <!-- FAB Button to Add Expense -->
        <app-fab-button @click="openAddModal"></app-fab-button>

        <!-- Add Expense Modal -->
        <add-modal
            ref="addModal"
            :is-add-modal-open="isAddModalOpen"
            @update:is-add-modal-open="isAddModalOpen = $event"
            @expense-actions="fetchExpensesData"
        ></add-modal>

        <!-- Edit Expense Modal -->
        <edit-modal
            ref="editModal"
            :is-edit-modal-open="isEditModalOpen"
            :selected-expense="selectedExpense"
            @update:is-edit-modal-open="isEditModalOpen = $event"
            @expense-actions="fetchExpensesData"
            @expense-edited="handleEditedExpenseDate"
        ></edit-modal>

    </div>

</template>
  
<script>
import { ref, onMounted, computed } from 'vue';
import { useToast } from 'vue-toastification';
import apiClient from '@/api/apiClient';
import AppLoader from "@/components/common/AppLoader.vue";
import AppTable from "@/components/common/AppTable";
import AppPagination from '@/components/common/AppPagination';
import AppFabButton from "@/components/common/AppFabButton";
import AddModal from "@/components/expenses/AddModal";
import EditModal from "@/components/expenses/EditModal";

export default {
    components: {
        AppLoader,
        AppTable,
        AppPagination,
        AppFabButton,
        AddModal,
        EditModal
    },
    setup() {
        const toast = useToast();
        const getCurrentYearAndMonth = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Add 1 to the month since it's zero-based
            return `${year}-${month}`;
        };
        const userId = ref(localStorage.getItem('userId'));
        const expenseColumns = [
            { key: "id", label: "ID", sort: true },
            { key: "subname", label: "Subcategory", sort: false },
            { key: "note", label: "Note", sort: false },
            { key: "date", label: "Date", sort: true },
            { key: "value", label: "Value", sort: true }
        ];
        const expensesData = ref([]);
        const selectedExpense = ref(null);
        const isAddModalOpen = ref(false);
        const isEditModalOpen = ref(false);
        const selectedSortOption = ref('id'); // Default sorting criteria
        const selectedSortDirection = ref('DESC'); // Default sorting order
        const actionsColumnLabel = ref(''); // Empty column label
        const totalExpense = ref(0);
        const selectedSetMonth = ref(getCurrentYearAndMonth()); //current yyyy-mm
        const selectedSubcategory = ref(null); // Initialize as null or an empty string
        const monthsList = ref([]);
        const subcategories = ref([]);
        const currentPage = ref(1);
        const totalPages = ref(0);
        const itemsPerPage = ref(20);
        const noDataMessage = ref('');
        const showRecurringOnly = ref(false);
        const loading = ref(false);
        const searchTerm = ref('');
        const showFilters = ref(false);

        // Computed Methods -----------------------------------------------
        // Compute the data for the current page based on currentPage and itemsPerPage
        const paginatedData = computed(() => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = startIndex + itemsPerPage.value;
            return expensesData.value.slice(startIndex, endIndex);
        });

        const groupedSubcategories = computed(() => {
            const grouped = {};

            // Loop through the subcategories data and group them by categories
            subcategories.value.forEach((subcategory) => {
                if (!grouped[subcategory.cat]) {
                    grouped[subcategory.cat] = [];
                }
                grouped[subcategory.cat].push(subcategory);
            });
            return grouped;
        });

        const filteredExpenses = computed(() => {
            if (!searchTerm.value) {
                return expensesData.value;
            }
            const searchTermLower = searchTerm.value.toLowerCase();
            return expensesData.value.filter(expense => {
                // Ensure that expense.note, expense.catname, and expense.subname are defined
                return (
                    (expense.note && expense.note.toLowerCase().includes(searchTermLower)) ||
                    (expense.catname && expense.catname.toLowerCase().includes(searchTermLower)) ||
                    (expense.subname && expense.subname.toLowerCase().includes(searchTermLower))
                );
            });
        });
        
        // Methods ------------------------------------------------------
        const fetchSubcategories = () => {
            apiClient.get('/get-subcategories.php')
            .then((response) => {
                // Set the subcategories data to the response from the backend
                subcategories.value = response.data;
            })
            .catch((error) => {
                console.error('Error fetching subcategories:', error);
            });
        };

        const updateSort = (option) => {
            if (selectedSortOption.value === option) {
                // If the same option is clicked, toggle the sort direction
                selectedSortDirection.value = selectedSortDirection.value === 'ASC' ? 'DESC' : 'ASC';
            } else {
                // If a new option is clicked, reset the sort direction to ascending
                selectedSortOption.value = option;
                selectedSortDirection.value = 'ASC';
            }
            fetchExpensesData();
        };

        const fetchExpensesData = () => {
            // Set loading to true before making the request
            loading.value = true;

            const requestData = {
                sortOption: selectedSortOption.value,
                sortDirection: selectedSortDirection.value,
                setMonth: selectedSetMonth.value,
                selectedSubcategory: selectedSubcategory.value,
                currentPage: currentPage.value,
                itemsPerPage: itemsPerPage.value,
                showRecurringOnly: showRecurringOnly.value,
                searchTerm: searchTerm.value
            };
            // Fetch expenses data from your backend API
            apiClient.post('/get-expenses.php', requestData)
            .then((response) => {
                expensesData.value = response.data.expensesData;
                totalExpense.value = response.data.totalExpense;
                monthsList.value = response.data.monthsList;
                totalPages.value = response.data.totalPages;

                if (response.data.expensesData.length <= 0) {
                    noDataMessage.value = 'No expenses to display. <br>Try filtering by month. <br>Or adding a new expense.';
                }
            })
            .catch((error) => {
                console.error('Error fetching expenses:', error);
            })
            .finally(() => {
                loading.value = false;
            });
        };
        
        const clearSearchTerm = () => {
            searchTerm.value = ''; // Clear the searchTerm
            fetchExpensesData(); // Optionally, trigger data refresh if needed
        };

        const editExpense = (expense) => {
            selectedExpense.value = expense;
            isEditModalOpen.value = true;
        };

        const handleEditedExpenseDate = (editedExpenseDate) => {
            // Reset the currentPage to 1 to ensure it's valid after filtering the expenses
            currentPage.value = 1;

            // Check if the EditModal updated selectedSetMonth
            if (editedExpenseDate === 'all') {
                // Get the updated date filter from the EditModal
                selectedSetMonth.value = editedExpenseDate;
            }

            // Fetch expenses data based on the selected month (whether it's 'all' or a specific date)
            fetchExpensesData();

            // Calculate the new totalPages based on the updated expensesData
            totalPages.value = Math.ceil(expensesData.value.length / itemsPerPage.value);
        };

        const deleteExpense = (expense) => {
            if (window.confirm('Are you sure you want to delete this expense?')) {
                apiClient.post('/delete-expense.php', { expense })
                .then((response) => {
                    if (response.data.success === true) {
                        // Update the expensesData after the deletion
                        expensesData.value = expensesData.value.filter((item) => item.id !== expense.id);

                        // Calculate the new totalPages based on the updated expensesData
                        totalPages.value = Math.ceil(expensesData.value.length / itemsPerPage.value);

                        // Go back to the first page if the current page becomes empty
                        if (expensesData.value.length === 0 && currentPage.value > 1) {
                            currentPage.value = 1;
                        }

                        // Get $dateFilter from delete-expenses to update selectedSetMonth based on 'all' when the date doesn't exists anymore or 'yyyy-mm' in case it does so it refreshs the table accordingly 
                        selectedSetMonth.value = response.data.deletedExpenseDate;

                        fetchExpensesData();

                        toast.success('Expense deleted successfully!');
                    } else {
                        console.error('Failed to delete expense.');
                    }
                })
                .catch((error) => {
                    console.error('Error deleting expense:', error);
                    toast.error('Error deleting expense!',);
                });
            }
        };

        const updateMonth = (selectedSetMonthValue) => {
            // Update the selectedSetMonth variable with the selected month
            selectedSetMonth.value = selectedSetMonthValue;

            // Reset currentPage to 1 when the month selection changes
            currentPage.value = 1;

            // Fetch expenses data based on the selected month
            fetchExpensesData();
        };

        const updateSubcategory = (selectedSubcategoryValue) => {
            if (selectedSubcategory.value !== selectedSubcategoryValue) {
                // Update the selectedSubcategory variable with the selected category
                selectedSubcategory.value = selectedSubcategoryValue;

                // Reset currentPage to 1 when the month selection changes
                currentPage.value = 1;

                // Fetch expenses data based on the selected month
                fetchExpensesData();
            }
        };
        
        const handleSortChange = (sortOption, sortDirection) => {
            selectedSortOption.value = sortOption;
            selectedSortDirection.value = sortDirection;
            fetchExpensesData(); // Call fetchExpensesData when sorting changes
        };
        
        const handlePageChange = (newPage) => {
            currentPage.value = newPage;
            fetchExpensesData(); // Fetch expenses data for the current page
        };

        const openAddModal = () => {
            fetchSubcategories();
            isAddModalOpen.value = true;
        };
    
        const openEditModal = () => {
            fetchSubcategories();
            isEditModalOpen.value = true;
        };

        const handleCheckboxChange = () => {
            // Reset currentPage to 1 when the checkbox state changes
            currentPage.value = 1;

            // Fetch expenses data based on the updated checkbox state
            fetchExpensesData();
        };

        const toggleFilters = () => {
            showFilters.value = !showFilters.value;
        };

        // Lifecycle hook
        onMounted(() => {
            fetchSubcategories();
            fetchExpensesData(); // Fetch expenses data with the default current month
        });

        return {
            userId,
            expenseColumns,
            expensesData,
            selectedExpense,
            isAddModalOpen,
            isEditModalOpen,
            selectedSortOption,
            selectedSortDirection,
            actionsColumnLabel,
            totalExpense,
            selectedSetMonth,
            selectedSubcategory,
            monthsList,
            subcategories,
            currentPage,
            totalPages,
            itemsPerPage ,
            noDataMessage,
            showRecurringOnly,
            loading,
            searchTerm,
            showFilters,
            paginatedData,
            groupedSubcategories,
            filteredExpenses,
            getCurrentYearAndMonth,
            fetchSubcategories,
            updateSort,
            fetchExpensesData,
            clearSearchTerm,
            editExpense,
            handleEditedExpenseDate,
            deleteExpense,
            updateMonth,
            updateSubcategory,
            handleSortChange,
            handlePageChange,
            openAddModal,
            openEditModal,
            handleCheckboxChange,
            toggleFilters
        };
    }
}
</script>
  
<style>
/* Add any custom styles specific to SetupView here */
.rec-icon {
    font-size: 1rem;
    color: rgba(239, 68, 68, 1);
}

.btn-filters {
    @apply inline-flex w-full justify-center items-center gap-2 rounded-md px-5 py-2 bg-gray-800 text-sm font-semibold text-white shadow-sm ring-2 ring-inset ring-sky-700 hover:bg-gray-900;
    /* width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    box-sizing: border-box;
    padding: 0.6rem 0.25rem 0.4rem 0.75rem;
    margin: 0.25rem 0;
    border-radius: 0.375rem;
    background-color: rgb(239 68 68);
    color: rgb(255 255 255);
    font-weight: 600;
    font-size: 0.875rem; */
}

.filters-wrapper {
    visibility: collapse;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1rem;
    margin: 0 0.5rem 1rem 0.5rem;
    box-sizing: border-box;
    border: rgba(17, 24,39, 1) solid 1px;
    border-top: none;
    border-radius: 0 0 0.5rem 0.5rem;
    transition: all 0.3s ease-in-out;
}

.filters-wrapper.show-filters {
    visibility: visible;
    max-height: 80vh;
    padding: 1rem;
    margin: -0.2rem 0.5rem 2rem 0.5rem;
    background-color: rgba(17, 24,39, 0.5);
}

/* Desktop View */
@media (min-width: 767px) {
    .btn-filters {
        display: none;
    }

    .filters-wrapper {
        visibility: visible;
        max-height: unset;
        display: flex;
        flex-direction: row;
        gap: 3rem;
        padding: 1.5rem 0 2rem 0;
        margin: unset;
        border: unset;
        place-items: end;
    }
}
</style>