<template>
    <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 rounded-lg shadow-lg bg-gray-800">

        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-white h-10 w-auto m-auto">
                <g>
                    <path d="M28.3334 0.833319C28.7811 0.833246 29.2205 0.953381 29.6059 1.18117C29.9913 1.40897 30.3084 1.73606 30.5242 2.12829L48.8569 35.4609C49.0151 35.7486 49.1151 36.0647 49.1512 36.391C49.1873 36.7174 49.1588 37.0477 49.0673 37.363C48.9757 37.6783 48.823 37.9725 48.6177 38.2288C48.4125 38.4851 48.1588 38.6985 47.8711 38.8567C47.5834 39.0149 47.2673 39.115 46.941 39.1511C46.6146 39.1871 46.2844 39.1586 45.9691 39.0671C45.6537 38.9755 45.3595 38.8228 45.1032 38.6175C44.8469 38.4123 44.6336 38.1586 44.4754 37.8709L29.0592 9.84813L28.3334 8.52066L27.6034 9.84813L12.1873 37.8709C11.8677 38.4519 11.3304 38.8822 10.6936 39.0671C10.0568 39.2519 9.37257 39.1763 8.79155 38.8567C8.21052 38.5371 7.78024 37.9998 7.59537 37.363C7.4105 36.7262 7.48617 36.042 7.80575 35.4609L26.1385 2.12829C26.3546 1.73544 26.6724 1.40795 27.0585 1.18011C27.4447 0.952281 27.885 0.832499 28.3334 0.833319ZM28.3334 2.94148e-06C27.7373 0.000701364 27.1522 0.161257 26.6393 0.464941C26.1263 0.768624 25.7042 1.20432 25.4168 1.72663L7.0841 35.0593C6.87312 35.4428 6.73975 35.8642 6.69161 36.2993C6.64346 36.7344 6.68149 37.1747 6.80352 37.5951C7.04997 38.4442 7.6236 39.1606 8.39823 39.5867C9.17285 40.0128 10.085 40.1137 10.9341 39.8673C11.3545 39.7452 11.7467 39.5416 12.0884 39.268C12.4301 38.9944 12.7146 38.6561 12.9256 38.2725L28.3334 10.2498L43.7495 38.2725C43.9605 38.6561 44.245 38.9944 44.5867 39.268C44.9284 39.5416 45.3207 39.7452 45.7411 39.8673C46.1615 39.9893 46.6018 40.0273 47.0369 39.9792C47.472 39.931 47.8933 39.7977 48.2769 39.5867C48.6605 39.3757 48.9987 39.0912 49.2723 38.7495C49.5459 38.4078 49.7496 38.0156 49.8716 37.5951C49.9936 37.1747 50.0317 36.7344 49.9835 36.2993C49.9354 35.8642 49.802 35.4428 49.591 35.0593L31.2583 1.72663C30.9703 1.20308 30.5468 0.766581 30.0323 0.46282C29.5177 0.159059 28.9309 -0.000790775 28.3334 2.94148e-06Z" />
                    <path d="M42.9203 35.0597L24.5876 1.72711C24.3 1.20409 23.8772 0.767893 23.3635 0.464084C22.8497 0.160275 22.2638 0 21.6669 0C21.07 0 20.4841 0.160275 19.9703 0.464084C19.4565 0.767893 19.0338 1.20409 18.7462 1.72711L0.417599 35.0597C0.206616 35.4433 0.0732455 35.8647 0.0251029 36.2998C-0.0230397 36.7349 0.0149885 37.1752 0.137016 37.5956C0.383462 38.4447 0.957095 39.161 1.73172 39.5872C2.50635 40.0133 3.41852 40.1142 4.26756 39.8677C4.68796 39.7457 5.08022 39.5421 5.42192 39.2685C5.76362 38.9948 6.04809 38.6566 6.25907 38.273L21.6669 10.2503L29.7791 25H19.7278L16.0612 31.6665H33.4457L37.083 38.273C37.5091 39.0477 38.2255 39.6213 39.0746 39.8677C39.9236 40.1142 40.8358 40.0133 41.6104 39.5872C42.385 39.161 42.9587 38.4447 43.2051 37.5956C43.4515 36.7466 43.3506 35.8344 42.9245 35.0597H42.9203Z" />
                </g>
            </svg>
            <!--<div class="p-4 mb-8 text-white text-center text-lg">A<sup>2</sup> Expenses</div>-->
            <div class="p-4 mb-8 text-white text-center text-lg">A<sup style="left:-0.2rem;">A</sup> Expenses</div>
        </div>

        <form @submit.prevent="login" class="mt-2 space-y-6 sm:mx-auto sm:w-full sm:max-w-sm">

            <div v-if="showFailedMessage" class="p-4 mb-8 bg-red-900 text-white rounded-md">
                Please check your credentials and try again.
            </div>

            <app-form-row label="Username" id="username">
                <input
                    type="text"
                    class="mt-1 block w-full rounded-md py-2 px-4 bg-gray-900 border-gray-900 border text-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                    id="username"
                    name="username"
                    v-model="username"
                    required
                >
            </app-form-row>

            <app-form-row label="Password" id="password">
                <input
                    type="password"
                    class="mt-1 block w-full rounded-md py-2 px-4 bg-gray-900 border-gray-900 border text-white
                         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white
                         sm:text-sm sm:leading-6"
                    id="password"
                    name="password"
                    v-model="password"
                    required
                >
            </app-form-row>

            <button
                type="submit"
                class="primary mt-1 w-full"
            >
                Login
            </button>

            <div class="flex w-full">
                <!-- <a href="/forgotuser">Forgot your password?</a>
                <a href="/newuser" class="ml-auto">Create an account</a> -->
                <a href="/newuser" class="m-auto">Create an account</a>
            </div>

        </form>
    </div>
</template>
  
<script>
import apiClient from '@/api/apiClient'; 
import router from '../router';
import AppFormRow from '../components/common/AppFormRow.vue';

export default {
    data() {
        return {
            username: '',
            password: '',
            showFailedMessage: false,
            isLoading: false
        };
    },
    components: {
        AppFormRow,
    },
    methods: {
        login() {
            this.isLoading = true;

            // Create an object with the login credentials
            const credentialsData = {
                username: this.username,
                password: this.password,
            };
            // console.log('credentials: ', credentialsData);
            // Make a POST request to the PHP backend with Axios
            apiClient.post('/login.php', credentialsData)
                .then((response) => {
                    // console.log('Login response.data:', response.data);
                    this.isLoading = false; // Reset loading state
                    if (response.data.success) {
                        // console.log(response.data);
                        // Store the user's info and JWT in localStorage
                        localStorage.setItem('userId', response.data.userId);
                        localStorage.setItem('expiration', response.data.expiration);
                        localStorage.setItem('jwt', response.data.jwt);

                        // Redirect to the dashboard page
                        router.push('/dashboard');
                    } else {
                        // console.log('Login failed!');
                        this.showFailedMessage = true;
                    }
                })
                .catch((error) => {
                    console.error('Error:', error.response.data);
                    this.isLoading = false; // Reset loading state on error
                });
        }
    }
};
</script>
  
<style>/* Your LoginView component styles here */</style>