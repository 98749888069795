import axios from 'axios';

// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000
});

// Intercept requests to add the Authorization header
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwt'); // method used to store the token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
